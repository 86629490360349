import Currency, { ICurrency } from "./Currency"

export declare type AccountUuid = string;

export interface IAccount {
    isSelectedByPlayer:boolean
    uuid: AccountUuid
    value: number
    valueWithCurrency: string
    currency:ICurrency
}

export default class Account  implements IAccount {
    
    isSelectedByPlayer:boolean
    uuid: AccountUuid
    value: number
    valueWithCurrency: string
    exchangedCurrency?: IAccount
    
    private _currency:Currency  
    
    constructor(data:any) {

        this.isSelectedByPlayer = false;
        this.uuid="";
        this.value = 0;
        this.valueWithCurrency = ""
        this._currency = new Currency({});
        
        Object.assign(this, data);         
    }

    get currency():Currency {return this._currency}
    set currency(value:Currency)  { this._currency = new Currency(value)}
    
    isDead():boolean { 
        return false
    }    

    getStringValue(showCurrency:boolean = true):string {
        return this.currency.formatValue(this.value, showCurrency);
    }
}

// currency:
// code_name: "EUR"
// entity: "NETHERLANDS (THE)"
// id: 978
// minor_unit: 2
// name: "Euro"
// separator: "."
// type_id: 0
// visible: true
// __proto__: Object
// isSelectedByPlayer: false
// uuid: "ff04f297-b5aa-41a0-a718-71029a97c322"
// value: 0
// valueWithCurrency: "0.00 EUR"