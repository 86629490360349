
export enum ECurrencyType {
    STANDARD        = 0,
    GENERIC         = 1,
    CHARACTERISTICS = 2,
    DESK            = 4,
    CHIPS           = 5,
}

export enum ECurrencyAbbreviation {
    CREDIT = 'Cr',
    GOLD = 'GLD',
    YAN = 'YAN',
    RUB = 'RUB'
}

export interface ICurrency {
    id: number
    entity: string
    name: string
    code_name: string
    minor_unit: number
    separator: string
    type_id: ECurrencyType   
    visible: boolean
    max_value: number
}

export default class Currency  implements ICurrency {
    id: number
    entity: string
    name: string
    code_name: string
    minor_unit: number
    separator: string
    type_id: ECurrencyType   
    visible: boolean
    max_value: number
    
    static readonly CURRENCY_ID_5STARSGAMMON_FUN = 10001;
    static readonly CURRENCY_ID_5STARSGAMMON_CREDIT  = 10011;
    static readonly CURRENCY_ID_5STARSGAMMON_RATING  = 10100;

    constructor(data:any) {
        this.id = 0;
        this.entity = ""
        this.name = ""
        this.code_name = "";
        this.minor_unit = 0;
        this.separator = "";
        this.type_id = ECurrencyType.STANDARD;
        this.visible = false;
        this.max_value = 0;

        Object.assign(this, data);
    }

    isItem() {
        return this.type_id == ECurrencyType.DESK || this.type_id == ECurrencyType.CHIPS
    }

    isMoney() {
        return this.type_id == ECurrencyType.STANDARD || this.type_id == ECurrencyType.GENERIC
    }        

    isDemo() {
        return this.id == Currency.CURRENCY_ID_5STARSGAMMON_FUN;
    }

    isRating() {
        return this.id == Currency.CURRENCY_ID_5STARSGAMMON_RATING;
    }
    
    formatValue( value:number,  showCode = false ):string {
        let valueStr:string = value+"";

        if(this.minor_unit > 0) {
            let len = valueStr.length;            
            let delimiterPos = len - this.minor_unit
            while(delimiterPos <= 0) {            
                valueStr = "0"+valueStr; 
                len  = valueStr.length;
                delimiterPos = len - this.minor_unit;
            }
            valueStr = valueStr.slice(0, delimiterPos) + this.separator + valueStr.slice(delimiterPos);        
        }
        
        if(showCode && this.visible) 
            valueStr += " "+this.code_name;
        
        return valueStr;
    }
}

